import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/ArticleLayout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`As I sat down in a half empty room located at Adobe headquarters and began to
look around it didn't take long for me to realize how truly privileged I was to
be there. I felt humbled. Actually, I felt like an impostor, as if I had
infiltrated a secret meeting of sages deciding the fate for something of massive
importance. In representation, there were members of TC39, W3C Tag, Angular,
Polymer, Ember &
`}<a parentName="p" {...{
        "href": "http://en.wikipedia.org/wiki/Tim_Berners-Lee"
      }}>{`Sir Tim Berners-Lee`}</a>{`. In fact, I
requested a picture with him for posterity but found myself feeling like a
`}<a parentName="p" {...{
        "href": "https://www.youtube.com/watch?v=FZQtRxsN_JU"
      }}>{`humiliated rejected Bieber fan`}</a>{`
when he declined my request, and asked me to focus on the content. Touchè Mr.
Lee. Below, is a summary of that very content. ;-)`}</p>
    <p>{`The event was started with Daniel Appelquist whom I believe was the organizer of
the event. It was to be run `}<a parentName="p" {...{
        "href": "http://en.wikipedia.org/wiki/BarCamp"
      }}>{`barcamp`}</a>{`
style, however, before the barcamp sessions there were a series of lightning
talks.`}</p>
    <p><img parentName="p" {...{
        "src": "/assets/images/articles/extensible-web-summit/final-sessions.jpg",
        "alt": "Final Sessions"
      }}></img></p>
    <h1>{`Lightning Talks`}</h1>
    <h2>{`Yehuda Katz`}</h2>
    <p>{`Yehuda noted that the "don't break the web" requirement means that browser
vendors don't have the liberty to "ship and iterate" in the traditional sense.
The `}<a parentName="p" {...{
        "href": "http://extensiblewebmanifesto.org/"
      }}>{`Extensible Web Manifesto`}</a>{` is about
providing primitives that allow developers to iterate in user space and
eventually merge that progression back into the platform. This enables iteration
and mitigates the burden of backward compatibility. It also empowers users to
progress the platform. He noted Polymer in particular as providing high-level
APIs in userland iteration, meanwhile leveraging that information to drive the
platform forward.`}</p>
    <h2>{`Jake Archibald`}</h2>
    <p>{`Jake started about by lamenting the unfortunate lack of features found in the
web platform such as push notifications, offline first, background updates,
payments, alarms, geofencing, etc. He then went on to explain a new technology,
`}<a parentName="p" {...{
        "href": "https://github.com/slightlyoff/ServiceWorker"
      }}>{`Service Workers`}</a>{`, that solves
many of these problems.`}</p>
    <h2>{`Angelina Fabbro`}</h2>
    <p>{`Angelina gave a quick overview of
`}<a parentName="p" {...{
        "href": "http://www.w3.org/TR/components-intro/"
      }}>{`Web Components`}</a>{`. Namely,`}</p>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "http://www.w3.org/TR/shadow-dom/"
        }}>{`Shadow DOM`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "http://www.w3.org/TR/html-templates/"
        }}>{`Templates`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "http://www.w3.org/TR/html-imports/"
        }}>{`HTML Imports`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "http://www.w3.org/TR/custom-elements/"
        }}>{`Custom Elements`}</a>{`.`}</li>
    </ul>
    <h2>{`Tab Atkins`}</h2>
    <p>{`Tab opened by conceding that CSS is by far the least extensible piece of the web
platform. He then went over different ideas to open up CSS for developer
empowerment. My favorite part was the optimistic and empowering close, "The
future of CSS is open for business".`}</p>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://developer.mozilla.org/en-US/docs/Web/CSS/Using_CSS_variables"
        }}>{`CSS Variables`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "http://tabatkins.github.io/specs/css-aliases/"
        }}>{`CSS Aliases`}</a></li>
    </ul>
    <h2>{`Domenic Denicola`}</h2>
    <p>{`Domenic shared his story about the
`}<a parentName="p" {...{
        "href": "http://promises-aplus.github.io/promises-spec/"
      }}>{`Promises/A+`}</a>{` specification in
which a community born specification is now a platform-wide primitive for
thinking about asynchrony. He is now working on a
`}<a parentName="p" {...{
        "href": "https://github.com/whatwg/streams"
      }}>{`streams`}</a>{` specification. I owe Domenic a
special bit of gratitude for convincing me to come to this meeting in the first
place, thank you, Domenic.`}</p>
    <h2>{`Jeni Tennison`}</h2>
    <p>{`Jeni shared her work on a
`}<a parentName="p" {...{
        "href": "https://github.com/w3ctag/packaging-on-the-web"
      }}>{`specification for packaging on the web`}</a>{`.
Today we optimize our applications using concatenation, however, you can't
concatenate ES6 modules due to module scoping. There is also a large amount of
work for mitigating requests, sprites or Base64 encoding images, CSS & JS
concatenation etc. Packages are an answer to this problem.`}</p>
    <h2>{`Alex Russell`}</h2>
    <p>{`Alex proposed the question, how does progress happen? He contended that progress
happens outside of the W3C, the W3C stamps that progress and they become
standards. Progress happens with:`}</p>
    <ul>
      <li parentName="ul">{`Evidence-based consensus, e.g. polyfill.`}</li>
      <li parentName="ul">{`Engineers have personal experience with a problem and they fix it.`}</li>
    </ul>
    <p>{`Progress starts with changing mind and ends with changing behavior. Delivering
meaningful progress on the web requires that we ship and use evidence to iterate
on the platform.`}</p>
    <h2>{`Anne van Kesteren`}</h2>
    <p>{`Anne told a story in which about a decade ago the HTML parser was standardized.
Prior to that time browsers implemented HTML in a variety of ways which meant
new specifications and standards were unreasonably difficult to agree upon and
implement. Once they got behind a standard strategy of parsing HTML a great deal
of progress was enabled. That work is leveraged as the underlying primitive for
many of the features we are working on today. The point of the story, so far as
I understood it, is that progress on the web platform requires a sort of
"archaeology" in which you unearth the underlying primitives in our existing
systems to propose meaningful ways of moving them forward. He noted a lot of
things need some archaeological work done, namely styling form controls and
content editing.`}</p>
    <p>{`All of the above happened in about thirty minutes, it was wonderful. Afterward,
we split up into the barcamp sessions in which I attended.`}</p>
    <ol>
      <li parentName="ol"><a parentName="li" {...{
          "href": "https://github.com/slightlyoff/ServiceWorker"
        }}>{`Service Workers`}</a></li>
      <li parentName="ol"><a parentName="li" {...{
          "href": "http://www.w3.org/TR/components-intro/"
        }}>{`Web Components`}</a></li>
      <li parentName="ol">{`Bed Rock - Unearthing Primitives - A crazy awesome wacky idea of using
MessageChannel's to provide low-level APIs.`}</li>
      <li parentName="ol"><a parentName="li" {...{
          "href": "https://github.com/slightlyoff/ServiceWorker"
        }}>{`Packaging on the Web`}</a></li>
      <li parentName="ol">{`Remote Debugging - Source Maps - I bailed out to go listen to
`}<a parentName="li" {...{
          "href": "https://twitter.com/mhevery"
        }}>{`Misko Hevery`}</a>{` discuss directive semantics in
Dart with `}<a parentName="li" {...{
          "href": "https://github.com/justinfagnani"
        }}>{`Justin Fagnani`}</a>{`.`}</li>
    </ol>
    <p>{`I considered posting my notes about the discussions and topics covered in these
sessions but I think that content is best explored in the meeting minutes found
on the events `}<a parentName="p" {...{
        "href": "http://lanyrd.com/2014/extensible-web-summit/"
      }}>{`lanyrd page`}</a>{`.`}</p>
    <p><img parentName="p" {...{
        "src": "/assets/images/articles/extensible-web-summit/packaging.jpg",
        "alt": "Discussing Web Packaging"
      }}></img>{`
`}<img parentName="p" {...{
        "src": "/assets/images/articles/extensible-web-summit/web-components.jpg",
        "alt": "Web Components"
      }}></img>{`
`}<img parentName="p" {...{
        "src": "/assets/images/articles/extensible-web-summit/drinks.jpg",
        "alt": "Drinks"
      }}></img>{`
`}<img parentName="p" {...{
        "src": "/assets/images/articles/extensible-web-summit/proposing-sessions.jpg",
        "alt": "Discussing Sessions"
      }}></img></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      